<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12" lg="12">
            <validation-provider
              #default="{ errors }"
              name="observacao"
              rules="required"
            >
              <b-form-group label="Profissional" label-for="v-profissional">
                <v-select
                  v-model="form.profissional"
                  label="nome"
                  :filterable="false"
                  :options="comboProfissionals"
                  :selected="form.profissional"
                  :disabled="!!form.id"
                  @search="onSearch"
                  ><template slot="no-options">
                    Pesquisa profissional por nome, cpf ou matrícula
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <small data-cy="v-profissional" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- first name -->
          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Data Inicial"
              rules="required"
            >
              <b-form-group label="Data Inicial" label-for="v-dataIncial">
                <flat-pickr
                  v-model="form.dataInicial"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d'
                  }"
                  :disabled="!!$route.params.id"
                />
                <small data-cy="v-dataIncial-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Hora Inicial"
              rules="required"
            >
              <b-form-group label="Hora Inicial" label-for="v-horaInicial">
                <flat-pickr
                  v-model="form.horaInicial"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    time_24hr: true
                  }"
                  :disabled="!!$route.params.id"
                />
                <small data-cy="v-horaInicial-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Data Fim"
              rules="required"
            >
              <b-form-group label="Data Fim" label-for="v-dataFim">
                <flat-pickr
                  v-model="form.dataFinal"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d'
                  }"
                  :disabled="!!$route.params.id"
                />
                <small data-cy="v-dataFim-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="Hora Final"
              rules="required"
            >
              <b-form-group label="Hora Final" label-for="v-horaFinal">
                <flat-pickr
                  v-model="form.horaFinal"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    time_24hr: true
                  }"
                  :disabled="!!$route.params.id"
                />
                <small data-cy="v-horaFinal-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12">
            <b-form-group label="Quantidade de Vagas" label-for="v-vagas">
              <b-form-input
                id="v-vagas"
                v-model="form.qtoVagas"
                type="number"
                placeholder="vagas"
                :disabled="!!$route.params.id"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="especialidadeControle.length > 1 && $route.params.id"
            cols="12"
            sm="12"
            md="12"
            lg="12"
          >
            <b-card-group deck>
              <b-card header="Quantidade de Vagas por Especialidade">
                <div
                  v-for="especialidade in especialidadeControle"
                  :key="especialidade.id"
                >
                  <b-input-group
                    :prepend="especialidade.descricao"
                    class="mt-1"
                  >
                    <b-form-input v-model="especialidade.qtd"></b-form-input>
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="addVagas(especialidade)"
                        >Adicionar quantidade</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>
        <b-row v-if="!$route.params.id">
          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Domingo</span>
            <b-form-checkbox v-model="form.domingo" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Segunda</span>
            <b-form-checkbox v-model="form.segunda" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Terça</span>
            <b-form-checkbox v-model="form.terca" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Quarta</span>
            <b-form-checkbox v-model="form.quarta" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Quinta</span>
            <b-form-checkbox v-model="form.quinta" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Sexta</span>
            <b-form-checkbox v-model="form.sexta" class="mr-2" switch />
          </b-col>

          <b-col cols="12" md="3" lg="2">
            <span class="font-weight-bold">Sabado</span>
            <b-form-checkbox v-model="form.sabado" class="mr-2" switch />
          </b-col>
        </b-row>
        <b-col v-else cols="12">
          <b-card v-if="!editandoAgenda" title="Agendas">
            <vue-good-table
              :columns="columns"
              :rows="agendamentosGerados"
              :rtl="direction"
              :pagination-options="{
                enabled: true,
                perPage: pageLength
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span v-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="editAgenda(props.row)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        data-cy="bloqueio"
                        @click="block(props.row)"
                      >
                        <feather-icon icon="XOctagonIcon" class="mr-50" />
                        <span>{{
                          !props.row.ativo ? 'Desbloquear' : 'Bloquear'
                        }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Mostrando 1 a </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3', '5', '10']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({
                            currentPerPage: value
                          })
                      "
                    />
                    <span class="text-nowrap">
                      de {{ props.total }} registros
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
          <b-card v-if="editandoAgenda" title="Editando Agenda">
            <b-row>
              <b-col cols="12" lg="12">
                <validation-provider
                  #default="{ errors }"
                  name="Data"
                  rules="required"
                >
                  <b-form-group label="Data" label-for="v-data">
                    <flat-pickr
                      v-model="objAgenda.data"
                      name="fieldDate"
                      :config="{
                        altInput: true,
                        altFormat: 'd/m/Y',
                        enableTime: false,
                        dateFormat: 'Y-m-d'
                      }"
                    />
                    <small data-cy="v-data-validate" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-card
              v-if="listaAtendimentosRelacionados.length > 0"
              header="Atedimentos Relacionados"
            >
              <b-list-group
                v-for="atendimento in listaAtendimentosRelacionados"
                :key="atendimento.id"
              >
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  style="cursor: pointer"
                  @click="selecionarAtendimento(atendimento.id)"
                >
                  <span style="color: #7367f0">{{
                    atendimento.numeroAtendimento
                  }}</span>
                  {{ atendimento.registro.nome }}
                  <b-badge variant="primary" pill>{{
                    atendimento.telefone
                  }}</b-badge>
                </b-list-group-item>
              </b-list-group>
            </b-card>
            <ButtonsFormVue
              :disable-form="!editandoAgenda"
              :permission-insert="verificarPermissao('ROLE_ATEND_INSERIR')"
              :permission-edit="verificarPermissao('ROLE_ATEND_ATUALIZAR')"
              @cancel="cancelarEditarAgenda"
              @save="salvarAgenda"
            />
          </b-card>
        </b-col>
        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="mr-1 mt-3"
              type="reset"
              variant="outline-secondary"
              data-cy="cancel"
              @click="cancelar"
            >
              Voltar
            </b-button>

            <b-button
              v-if="!$route.params.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1 mt-3"
              data-cy="save"
              @click="save"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox,
  BFormTextarea,
  BCalendar,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCardGroup,
  BCardBody,
  BCardHeader,
  BListGroup,
  BListGroupItem,
  BBadge,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import mixinsGlobal from '@/mixins'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import _ from 'lodash'

export default {
  computed: {
    ...mapState('agendamentoModule', {
      agendamento: (state) => state.agendamento
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BFormTextarea,
    BCalendar,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ButtonsFormVue,
    BCardBody,
    BCardGroup,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BBadge,
    BInputGroup,
    BInputGroupAppend
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  data() {
    return {
      name: 'FormAgenda',
      value: '',
      context: null,
      titleForm: 'Agendamento',
      seachTerm: '',
      options: [],
      comboProfissionals: [],
      required,
      loading: false,
      form: {
        id: null,
        dataInicial: '',
        dataFinal: '',
        horaInicial: '',
        horaFinal: '',
        profissional: null,
        ativo: true,
        qtoVagas: 0,
        repetir: '',
        domingo: false,
        segunda: true,
        terca: true,
        quarta: true,
        quinta: true,
        sexta: true,
        sabado: false,
        semana: 0
      },
      agendamentosGerados: [],
      columns: [
        {
          label: 'Data',
          field: 'data',
          formatFn: this.formatarData
        },
        {
          label: 'Vagas Disponível',
          field: 'qtoDisponivel'
        },
        {
          label: 'Bloqueado',
          field: 'ativo',
          formatFn: (value) => (value ? 'Não' : 'Sim')
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc'
      },
      pageLength: 10,
      editandoAgenda: false,
      objAgenda: {
        id: null,
        data: ''
      },
      listaAtendimentosRelacionados: [],
      disableForm: false,
      especialidadeControle: []
    }
  },
  methods: {
    addVagas(especialidade) {
      const obj = {
        especialidade: especialidade.id,
        qto: especialidade.qtd,
        agendamento: this.form.id
      }

      this.$store
        .dispatch('agendamentoModule/controleVagasEspecialidade', obj)
        .then((response) => {
          if (response.response) {
            this.MensagemError(
              'Falha ao Editar',
              `${response.response.data.message}`
            )
            return
          }
          if (response.error) {
            this.MensagemError('Falha ao Editar', `${response.message}`)
            return
          }
          this.showMessageSuccess('Agendamento', 'Vagas Adicionadas!')
        })
    },
    selecionarAtendimento(id) {
      this.$swal({
        title: 'Redirecionar para o atendimento?',
        text: 'Voce tem certeza que deseja ir para o atendimento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            path: `/modules/attendance/attendance/editAtendimento/${id}`
          })
        }
      })
    },
    dataParaFormulario(stringData) {
      const partes = stringData.split('-')
      const data = new Date(partes[2], partes[1] - 1, partes[0])
      return data
    },
    preparaDataParaSalvarEdicao(data) {
      const dataRecebida = new Date(`${data}T00:00:00`)
      const dia = dataRecebida.getDate()
      const mes = dataRecebida.getMonth() + 1 // Os meses começam em zero, então adicionamos 1
      const ano = dataRecebida.getFullYear()
      // Formatando a data no formato desejado: DD-MM-YYYY
      const dataFormatada = `${dia < 10 ? `0${dia}` : dia}-${
        mes < 10 ? `0${mes}` : mes
      }-${ano}`
      return dataFormatada
    },
    salvarAgenda() {
      const obj = {
        id: this.objAgenda.id,
        data: this.preparaDataParaSalvarEdicao(this.objAgenda.data)
      }
      this.$store
        .dispatch('agendamentoModule/editAgenda', obj)
        .then((response) => {
          if (response.response) {
            this.MensagemError(
              'Falha ao Editar',
              `${response.response.data.message}`
            )
            return
          }
          if (response.error) {
            this.MensagemError('Falha ao Editar', `${response.message}`)
            return
          }
          this.showMessageSuccess('Agendamento', 'Editado com sucesso!')
          this.cancelarEditarAgenda()
          this.carregaAgendasPorId()
        })
    },
    editAgenda(data) {
      this.listaAtendimentosRelacionados = []
      this.editandoAgenda = true
      this.objAgenda = {
        id: data.id,
        data: this.dataParaFormulario(data.data)
      }
      const obj = {
        id: this.form.id,
        data: data.data
      }
      this.$store
        .dispatch('agendamentoModule/carregarAtendimentos', obj)
        .then((response) => {
          response.data.content.forEach(async (item) => {
            const telefones = await this.getPhones(item.registro.id)
            item.telefone = 'Nunhem Telefone Cadastrado!'
            if (telefones.length > 0) {
              item.telefone = telefones[0].numero
            }
            this.listaAtendimentosRelacionados.push(item)
          })
        })
    },
    async getPhones(id) {
      const telefones = await this.$store.dispatch(
        'foneSettingsModule/getFoneByIdRegister',
        id
      )
      return telefones.data
    },
    cancelarEditarAgenda() {
      this.editandoAgenda = false
      this.objAgenda = {
        id: null,
        data: ''
      }
    },
    formatarData(val) {
      if (val) {
        const data = val.replaceAll('-', '/')
        return data
      }
      return val
    },
    prepareForSave(data) {
      const objectSave = JSON.parse(JSON.stringify(data))
      const { dataInicial } = objectSave
      const { dataFinal } = objectSave
      if (data.id) {
        return objectSave
      }
      const obj = {
        id: objectSave.id ? objectSave.id : undefined,
        dataInicial,
        horaInicial: objectSave.horaInicial,
        horaFinal: objectSave.horaFinal,
        dataFinal,
        profissional: { id: objectSave.profissional.id },
        ativo: objectSave.ativo,
        qtoVagas: objectSave.qtoVagas,
        domingo: objectSave.domingo,
        segunda: objectSave.segunda,
        terca: objectSave.terca,
        quarta: objectSave.quarta,
        quinta: objectSave.quinta,
        sexta: objectSave.sexta,
        sabado: objectSave.sabado,
        semana: objectSave.semana
      }
      return obj
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          const objectSave = this.prepareForSave(this.form)
          if (objectSave.id) {
            try {
              this.$store.dispatch('agendamentoModule/edit', objectSave)
              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              this.cancelar()
              return
            } catch (error) {
              this.showMessageSuccess('Editar', 'Falha ao editar!')
            }
          }
          this.$store
            .dispatch('agendamentoModule/insert', objectSave)
            .then((response) => {
              if (response.response) {
                this.MensagemError(
                  `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
                  `${response.response.data.errors[0].message}`
                )
                this.showForm = true
                return
              }
              if (response.error) {
                this.MensagemError('Falha ao Inserir', `${response.message}`)
                this.showForm = true
                return
              }
              this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
              this.cancelar()
            })
        }
      })
    },
    async cancelar() {
      this.$emit('cancelar')
      this.cleanObjeto()
      await this.$store.dispatch('agendamentoModule/reset')
      this.$router.push({ name: 'agendamento' })
    },
    cleanObjeto() {
      this.form = {
        id: null,
        dataInicial: '',
        dataFinal: '',
        horaInicial: '',
        horaFinal: '',
        profissional: null,
        ativo: true,
        qtoVagas: 0,
        domingo: false,
        segunda: true,
        terca: true,
        quarta: true,
        quinta: true,
        sexta: true,
        sabado: false,
        semana: 0
      }
      this.agendamentosGerados = []
    },
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce(async (loading, search, vm) => {
      const registrosEncontrados = await vm.$store.dispatch(
        'agendamentoModule/searchProfessional',
        search
      )
      if (registrosEncontrados.response) {
        vm.MensagemError(
          'Falha na busca',
          `${registrosEncontrados.response.data.message}`
        )
        return
      }

      if (registrosEncontrados.data.length === 0) {
        vm.MensagemError('Zero profissionais', 'Nenhum profissional encontrado')
        return
      }

      vm.comboProfissionals = registrosEncontrados.data.content.map((item) => ({
        id: item.id,
        nome: item.nome
      }))
      loading(false)
    }, 350),
    async carregaAgendasPorId() {
      const agendas = await this.$store.dispatch(
        'agendamentoModule/agendasById',
        this.form.id
      )
      this.agendamentosGerados = agendas.data
    },
    block(data) {
      this.$swal({
        title: `${!data.ativo ? 'Desbloquear  Data ?' : 'Bloquear  Data ?'}`,
        text: `Deseja realmente  ${
          !data.ativo ? 'Desbloquear' : 'Bloquear'
        } esta data?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: !data.ativo ? 'Desbloquear' : 'Bloquear',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          if (data.ativo) {
            this.$store
              .dispatch('agendamentoModule/bloquearDataById', data.id)
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Bloquear',
                    `${response.response.data.message}`
                  )
                  return
                }
                if (response.error) {
                  this.MensagemError('Falha ao Bloquear', `${response.message}`)
                  return
                }
                this.showMessageSuccess('Bloqueado', `Bloqueado com sucesso!`)
                this.carregaAgendasPorId()
              })
          } else {
            this.$store
              .dispatch('agendamentoModule/desbloquearDataById', data.id)
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Desbloquear',
                    `${response.response.data.message}`
                  )
                  return
                }
                if (response.error) {
                  this.MensagemError(
                    'Falha ao Desbloquear',
                    `${response.message}`
                  )
                  return
                }
                this.showMessageSuccess(
                  'Desbloquear',
                  `Desbloqueado com sucesso!`
                )
                this.carregaAgendasPorId()
              })
          }
        }
      })
    }
  },
  async mounted() {
    this.disableForm = false
    this.cleanObjeto()
    this.loading = false
    if (this.$route.params.id) {
      this.disableForm = true
      this.aplicarEstiloDisabilitadoEmCampoData()
      await this.$store.dispatch(
        'agendamentoModule/getById',
        this.$route.params.id
      )
    }
    this.form = JSON.parse(JSON.stringify(this.agendamento))

    if (this.$route.params.id) {
      this.$store
        .dispatch(
          'agendamentoModule/getControleQtdVagas',
          this.$route.params.id
        )
        .then((response) => {
          this.especialidadeControle =
            this.form.profissional.especialidades.map((item) => ({
              id: item.id,
              descricao: item.descricao,
              qtd: null
            }))
          this.especialidadeControle.forEach((especialidade) => {
            especialidade.qtd = response.data.find(
              (item) => item.especialidade.id === especialidade.id
            )?.qto
          })
        })

      this.carregaAgendasPorId()
    }
    this.loading = false
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
